import React, { useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import "../../css files/developmentsec.css";
import "../../css files/readmoreservice.css";
import Navbar from "../../../components/Navbar";
import ContentOfReadMoreService from "./ContentOfReadMoreService";
import ContactUsForm from "../../contact us/ContactUsForm";
import Footer from "../../../components/Footer";

function DevelopmentSection(props) {
  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {}, [state]);

  return (
    <>
      <section className="bg_read_more_section">
        <div className="bg_read_more_hero_section_container">
          <div className="nav_bar_under_text_container">
            <Navbar />
          </div>

          <div className="heading_hero_section_container">
            <div className="green_read_more_container_heading">
              <div className="work_title_text_container">
                <div
                  className="our_work_read_more_title_text"
                  dangerouslySetInnerHTML={{
                    __html: state.headerReadMore,
                  }}
                >
                  {/* {state.headerReadMore} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContentOfReadMoreService data={state} />
      <ContactUsForm />
      <Footer />
    </>
  );
}

export default DevelopmentSection;
