import React, { useEffect, useState } from "react";
import "../pages/css files/fixbtn.css";
const whatsApp_img = require("../assets/whatsapp.png");

function FixButton(props) {
  return (
    <a href="https://wa.me/c/+18628461916" className="right_fix_btn">
      <img src={whatsApp_img} alt="ds_whatsApp" loading="lazy" />
    </a>
  );
}

export default FixButton;
