import React from "react";
import "../css files/workflow.css";
const flow_img1 = require("../../assets/undestand.svg");
const flow_img2 = require("../../assets/communication.svg");
const flow_img3 = require("../../assets/design.svg");
const flow_img4 = require("../../assets/code.svg");
const flow_img5 = require("../../assets/testing.svg");
const flow_img6 = require("../../assets/analyze.svg");
const flow_img7 = require("../../assets/finaltest.svg");
const flow_img8 = require("../../assets/deliver.svg");
const arrow_img1 = require("../../assets/Arrow 1.svg");
const arrow_img2 = require("../../assets/arrow2.svg");
const arrow_img3 = require("../../assets/arrow3.svg");
const arrow_img4 = require("../../assets/arrow4.svg");
const arrow_img5 = require("../../assets/arrow5.svg");
const arrow_img6 = require("../../assets/arrow6.svg");
const arrow_img7 = require("../../assets/arrow7.svg");

function WorkFlowSection(props) {
  return (
    <section className="main_work_flow_container" id="workflow">
      <div className="work_flow_under_main_container">
        <div className="workflow_text_container">
          <p className="work_flow_text_heading">
            How does our{" "}
            <span className="highlighted_title_text">work flow</span> go
          </p>
        </div>

        <div className="workflow_body_container">
          <div className="workflow_under_body_one_container">
            <div className="workflow_one_container same_work_flow_container_style">
              <div className="workflow_icon_container">
                <img src={flow_img1.default} alt="flow_img" />
              </div>
              <p className="work_flow_text">1. Understand</p>
            </div>
            <img
              src={arrow_img1.default}
              alt="arrow_img"
              className="arrow_img_1"
            />

            <div className="workflow_two_container same_work_flow_container_style">
              <div className="workflow_icon_container">
                <img src={flow_img2.default} alt="flow_img" />
              </div>
              <p className="work_flow_text">2. Communication</p>
            </div>
            <img
              src={arrow_img2.default}
              alt="arrow_img"
              className="arrow_img_2"
            />

            <div className="workflow_three_container same_work_flow_container_style">
              <div className="workflow_icon_container">
                <img src={flow_img3.default} alt="flow_img" />
              </div>
              <p className="work_flow_text">3. Design</p>
            </div>
            <img
              src={arrow_img3.default}
              alt="arrow_img"
              className="arrow_img_3"
            />

            <div className="workflow_four_container same_work_flow_container_style">
              <div className="workflow_icon_container">
                <img src={flow_img4.default} alt="flow_img" />
              </div>
              <p className="work_flow_text">4. Code</p>
            </div>
          </div>
          <img
            src={arrow_img4.default}
            alt="arrow_img"
            className="arrow_img_4"
          />

          <div className="workflow_fifth_container same_work_flow_container_style">
            <div className="workflow_icon_container1">
              <img
                src={flow_img5.default}
                alt="flow_img"
                className="work_design_img"
              />
            </div>
            <p className="work_flow_text">5. Testing</p>
          </div>

          <img
            src={arrow_img5.default}
            alt="arrow_img"
            className="arrow_img_5"
          />

          <div className="workflow_six_container same_work_flow_container_style">
            <div className="workflow_icon_container1">
              <img
                src={flow_img6.default}
                alt="flow_img"
                className="work_design_img"
              />
            </div>
            <p className="work_flow_text">6. Analyzing</p>
          </div>
          <img
            src={arrow_img6.default}
            alt="arrow_img"
            className="arrow_img_6"
          />
          <div className="workflow_seven_container same_work_flow_container_style">
            <div className="workflow_icon_container1">
              <img
                src={flow_img7.default}
                alt="flow_img"
                className="work_design_img"
              />
            </div>
            <p className="work_flow_text">7. Final Test</p>
          </div>
          <img
            src={arrow_img7.default}
            alt="arrow_img"
            className="arrow_img_7"
          />
          <div className="workflow_eight_container same_work_flow_container_style">
            <div className="workflow_icon_container1">
              <img
                src={flow_img8.default}
                alt="flow_img"
                className="work_design_img"
              />
            </div>
            <p className="work_flow_text">8. Deliver</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorkFlowSection;
