import React from "react";

import { IoMdClose } from "react-icons/io";
import "./custdialogbox.css";

export default function CustDialogBox({
  dialogHeaderName,
  children,
  onClose,
  open,
}) {
  if (!open) return null;

  return (
    <>
      <div className="overlay_styles1" />
      <div className="modal_styles1">
        <div className="close_btn_headertext_container">
          <h6 className="edit_dialog_header">{dialogHeaderName}</h6>
          <button onClick={onClose} className="close_btn_dialog">
            <IoMdClose className="md_close_btn_icon1" />
          </button>
        </div>
        {children}
      </div>
    </>
  );
}
