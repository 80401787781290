import React, { useEffect, useState } from "react";
import { CarouselWrapper } from "react-pretty-carousel";
const pic_img = require("../../assets/work/proj1.png");
const pic_img1 = require("../../assets/work/proj2.png");
const pic_img2 = require("../../assets/work/proj3.png");
const pic_img3 = require("../../assets/work/proj4.png");
const pic_img4 = require("../../assets/work/proj5.png");

function CardNews(props) {
  const images = [
    {
      imgName: pic_img,
    },
    {
      imgName: pic_img1,
    },
    {
      imgName: pic_img2,
    },

    {
      imgName: pic_img3,
    },
    {
      imgName: pic_img4,
    },
  ];
  return (
    <div>
      <CarouselWrapper items={3} mode="gallery">
        {images.map((img) => {
          return (
            <div key={Math.random()}>
              <img src={img.imgName} alt="img" />
            </div>
          );
        })}
      </CarouselWrapper>
    </div>
  );
}

export default CardNews;
