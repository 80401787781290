import React, { useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./slider.css";
import { Navigation, Pagination } from "swiper";

const res_img = require("../../assets/work/dwecUserPanel_img.png");
const res_img1 = require("../../assets/work/dietdelightpic_img.png");
const res_img2 = require("../../assets/work/strideahead_img.png");
const res_img3 = require("../../assets/work/strideAhead_img1.png");
const res_img4 = require("../../assets/work/work.png");
const res_img5 = require("../../assets/work/dwec_img.png");

const allRenderImg = [
  {
    imgName: res_img,
  },
  {
    imgName: res_img1,
  },
  {
    imgName: res_img2,
  },
  {
    imgName: res_img3,
  },
  {
    imgName: res_img4,
  },
  {
    imgName: res_img5,
  },
];

export default function App() {
  return (
    <>
      <Swiper
        rewind={true}
        navigation={true}
        pagination={true}
        modules={[Navigation, Pagination]}
        className="mySwiper"
      >
        {allRenderImg.map((perRenderImg) => {
          return (
            <SwiperSlide key={Math.random()}>
              <div className="responsive_img_container">
                <img
                  src={perRenderImg.imgName}
                  alt={perRenderImg.imgName}
                  className="responsive_img_self"
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
