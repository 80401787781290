import React from "react";
import { useNavigate } from "react-router-dom";
const job_hunt = require("../../assets/career/job_hunt.svg");

function CareerDetailPage(props) {
  const navigate = useNavigate();
  return (
    <section className="career_main_container">
      <div className="career_under_main_container">
        <div className="career_detail_flex_container">
          <div className="career_left_details_container">
            <div className="career_img_container">
              <img src={job_hunt.default} alt="ds_img" />
            </div>
          </div>
          <div className="career_right_details_container">
            <p className="career_title_text">
              MAKING KNOWLEDGE <br /> ACCESSIBLE TO ALL
            </p>
            <p className="career_extra_content_text">
              We have a world-class team working on an era-defining solution. We
              want to reimagine what it means to discover canonical knowledge.
              We have a world-class team working on an era-defining solution. We
              want to reimagine what it means to discover canonical knowledge.
            </p>
            <button
              className="position_see_btn"
              onClick={() => navigate("/JoinSquadMain")}
            >
              See position
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CareerDetailPage;
