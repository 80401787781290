import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ContactUsForm from "./contact us/ContactUsForm";
import "./css files/privacy.css";

function PrivacyPolicy(props) {
  return (
    <>
      <section className="bg_read_more_section">
        <div className="bg_read_more_hero_section_container">
          <div className="nav_bar_under_text_container">
            <Navbar />
          </div>

          <div className="heading_hero_section_container">
            <div className="green_read_more_container_heading">
              <div className="work_title_text_container">
                <p className="our_work_read_more_title_text">
                  PRIVACY <br />
                  POLICY
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="widt_res_container">
        <div>
          <p>
            At <strong>Developer Squad Team</strong> ,{" "}
            <strong>accessible</strong> from{" "}
            <strong>
              <a class="link_text" href="https://developersquad575.co.in/#/">
                https://developersquad575.co.in
              </a>
            </strong>
            , one of our main priorities is the privacy of our visitors. This
            Privacy Policy document contains types of information that is
            collected and recorded by developersquad575.co.in and how we use it.
          </p>
          <br />
          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to <strong>contact us.</strong>
          </p>
          <br />
          <p>
            By using our website, you hereby <strong>consent</strong> to our{" "}
            <strong>Privacy Policy</strong> and <strong>agree</strong> to its{" "}
            <strong>terms</strong>.
          </p>
          <br />
          <p style={{ paddingLeft: 40 }}>
            The personal information that you are asked to provide, and the
            reasons why you are asked to provide it, will be made clear to you
            at the point we ask you to provide your personal information.
          </p>
          <p style={{ paddingLeft: 40 }}>
            If you contact us directly, we may receive additional information
            about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any
            other information you may choose to provide.
          </p>
          <p style={{ paddingLeft: 40 }}>
            When you register for an Account, we may ask for your contact
            information, including items such as name, company name, address,
            email address, and telephone number.
          </p>
          <br />
          <h4>
            <span> ➩</span> How we use your information
          </h4>
          <br />
          <p>
            We use the information we collect in various ways, including to:
          </p>
          <br />
          <ul style={{ listStyleType: "circle", paddingLeft: 40 }}>
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our
              partners, including for customer service, to provide you with
              updates and other information relating to the website, and for
              marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
          <br />
          <h4>
            <span> ➩</span> Log Files
          </h4>
          <br />
          <p>
            developersquad575.co.in follows a standard procedure of using log
            files. These files log visitors when they visit websites. All
            hosting companies do this and a part of hosting services' analytics.
            The information collected by log files include internet protocol
            (IP) addresses, browser type, Internet Service Provider (ISP), date
            and time stamp, referring/exit pages, and possibly the number of
            clicks. These are not linked to any information that is personally
            identifiable. The purpose of the information is for analyzing
            trends, administering the site, tracking users' movement on the
            website, and gathering demographic information.
          </p>
          <br />
          <h4>
            <span> ➩</span> Advertising Partners Privacy Policies
          </h4>
          <br />
          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of developersquad575.co.in.
          </p>
          <br />
          <p>
            Third-party ad servers or ad networks uses technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on
            developersquad575.co.in, which are sent directly to users' browser.
            They automatically receive your IP address when this occurs. These
            technologies are used to measure the effectiveness of their
            advertising campaigns and/or to personalize the advertising content
            that you see on websites that you visit.
          </p>
          <br />
          <p>
            Note that developersquad575.co.in has no access to or control over
            these cookies that are used by third-party advertisers.
          </p>
          <br />
          <h4>
            <span> ➩</span> Third Party Privacy Policies
          </h4>
          <br />
          <p>
            developersquad575.co.in's Privacy Policy does not apply to other
            advertisers or websites. Thus, we are advising you to consult the
            respective Privacy Policies of these third-party ad servers for more
            detailed information. It may include their practices and
            instructions about how to opt-out of certain options.
          </p>
          <br />
          <p>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites.
          </p>
          <br />
          <h4>
            <span> ➩</span> CCPA Privacy Rights (Do Not Sell My Personal
            Information)
          </h4>
          <br />
          <p>
            Under the CCPA, among other rights, California consumers have the
            right to:
          </p>
          <p>&nbsp;</p>
          <ul>
            <li style={{ listStyleType: "none", paddingLeft: 40 }}>
              <ul>
                <li>
                  Request that a business that collects a consumer's personal
                  data disclose the categories and specific pieces of personal
                  data that a business has collected about consumers.
                </li>
                <li>
                  Request that a business delete any personal data about the
                  consumer that a business has collected.
                </li>
                <li>
                  Request that a business that sells a consumer's personal data,
                  not sell the consumer's personal data.
                </li>
                <li>
                  If you make a request, we have one month to respond to you. If
                  you would like to exercise any of these rights, please contact
                  us.
                </li>
              </ul>
            </li>
          </ul>
          <br />
          <h4>
            <span> ➩</span> GDPR Data Protection Rights
          </h4>
          <br />
          <p>
            We would like to make sure you are fully aware of all of your data
            protection rights. Every user is entitled to the following:
          </p>
          <br />
          <ol>
            <li style={{ listStyleType: "none", paddingLeft: 40 }}>
              <ol>
                <li>
                  The right to access &ndash; You have the right to request
                  copies of your personal data. We may charge you a small fee
                  for this service.
                </li>
                <li>
                  The right to rectification &ndash; You have the right to
                  request that we correct any information you believe is
                  inaccurate. You also have the right to request that we
                  complete the information you believe is incomplete.
                </li>
                <li>
                  The right to erasure &ndash; You have the right to request
                  that we erase your personal data, under certain conditions.
                </li>
                <li>
                  The right to restrict processing &ndash; You have the right to
                  request that we restrict the processing of your personal data,
                  under certain conditions.
                </li>
                <li>
                  The right to object to processing &ndash; You have the right
                  to object to our processing of your personal data, under
                  certain conditions.
                </li>
                <li>
                  The right to data portability &ndash; You have the right to
                  request that we transfer the data that we have collected to
                  another organization, or directly to you, under certain
                  conditions.
                </li>
              </ol>
            </li>
          </ol>
          <br />
          <p>&nbsp;</p>
          <p>
            If you make a request, we have one month to respond to you. If you
            would like to exercise any of these rights, please contact us.
          </p>
          <br />
          <h4>
            <span> ➩</span> Children's Information
          </h4>
          <br />
          <p style={{ paddingLeft: 40 }}>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>
          <p style={{ paddingLeft: 40 }}>
            developersquad575.co.in does not knowingly collect any Personal
            Identifiable Information from children under the age of 13. If you
            think that your child provided this kind of information on our
            website, we strongly encourage you to contact us immediately and we
            will do our best efforts to promptly remove such information from
            our records.
          </p>
        </div>
      </div>

      <ContactUsForm />
      <br />
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
