import React from "react";
import "../css files/sixboxcareer.css";
const icon_six = require("../../assets/career/icon_six.svg");

function SixBoxCareer(props) {
  const allSixBoxContent = [
    {
      iconName: icon_six,
      colorName: "#f4f4f4",
      iconTitle: "title name",
      iconSubTitle: ` Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.`,
    },
    {
      iconName: icon_six,
      colorName: "#d9d9d9",
      iconTitle: "title name",
      iconSubTitle: ` Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book.`,
    },
    {
      iconName: icon_six,
      colorName: "#f4f4f4",
      iconTitle: "title name",
      iconSubTitle: ` Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book.`,
    },
    {
      iconName: icon_six,
      colorName: "#d9d9d9",
      iconTitle: "title name",
      iconSubTitle: ` Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book.`,
    },
    {
      iconName: icon_six,
      colorName: "#f4f4f4",
      iconTitle: "title name",
      iconSubTitle: ` Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book.`,
    },
    {
      iconName: icon_six,
      colorName: "#d9d9d9",
      iconTitle: "title name",
      iconSubTitle: ` Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of type
              and scrambled it to make a type specimen book.`,
    },
  ];
  return (
    <div className="career_under_main_container">
      <section className="six_box_career_container">
        {allSixBoxContent.map((perSixBox) => {
          return (
            <div
              className="six_box_container"
              style={{ backgroundColor: perSixBox.colorName }}
            >
              <div className="img_container_six">
                <img src={perSixBox.iconName.default} alt="six_img" />
              </div>
              <p className="six_box_title">{perSixBox.iconTitle}</p>
              <p className="six_box_subtitle">{perSixBox.iconSubTitle}</p>
            </div>
          );
        })}
      </section>
    </div>
  );
}

export default SixBoxCareer;
