import React from "react";
const img_service = require("../../../assets/service_img.svg");
const development_pic = require("../../../assets/aboutus/Product iteration-bro.svg");

function ContentOfReadMoreService(props) {
  console.log(props.data, "line 5");
  return (
    <section className="main_content_read_more_container">
      <div className="under_main_read_more_container">
        <div className="content_service_container">
          <div className="content_left_service_container">
            <div className="content_img_container">
              <img src={img_service.default} alt="img_service" />
            </div>
          </div>
          <div
            className="content_right_service_container"
            dangerouslySetInnerHTML={{
              __html: props.data.firstParagraph,
            }}
          ></div>
        </div>
        <div
          className="center_service_text"
          dangerouslySetInnerHTML={{
            __html: props.data.secParagraph,
          }}
        ></div>
        <div className="development_pic_container_center">
          <img
            src={development_pic.default}
            alt="ds"
            className="development_pic"
          />
        </div>
        <div
          className="end_of_content_container"
          dangerouslySetInnerHTML={{
            __html: props.data.thirdParagraph,
          }}
        ></div>
      </div>
    </section>
  );
}

export default ContentOfReadMoreService;
