import axios from "axios";

export async function contactUs(data) {
  let response = await axios
    .post(
      "https://ds575backendapi.developersquad575.co.in/api/ds575/contactUs",
      data
    )
    .then((response) => {
      console.log(response.data);
      return response.data;
      //   data.contactUsResponse =
      //     "Your Request to contact Us have been Received. We will get in touch to You Soon!!!";
      //   console.log(data.contactUsResponse);
    })
    .catch((error) => {
      //   data.contactUsResponse = "Something Went Wrong!!!";
      console.log(error.response);
      return error.response;
      //   console.log(data.contactUsResponse);
    });
  return response;
}
