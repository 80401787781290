import React from "react";
import "../css files/companyabout.css";
const ds_logo = require("../../assets/aboutus/ds_logo.svg");
// const ds_logo = require("../../assets/ds_logo_12.svg");
const instaImg = require("../../assets/aboutus/Vector-2.svg");
const gBusinessImg = require("../../assets/aboutus/Vector-1.svg");
const fBImg = require("../../assets/aboutus/Vector.svg");
const linkdLnImg = require("../../assets/aboutus/Vector-3.svg");
const grow_img = require("../../assets/grow.svg");

const socialLogo = [
  {
    logoName: instaImg,
    logoLink: `https://www.instagram.com/developersquad.in/`,
  },
  {
    logoName: gBusinessImg,
    logoLink: `https://g.page/r/CTPz22E7HHbHEAc`,
  },
  {
    logoName: fBImg,
    logoLink: `https://www.facebook.com/developersquad.in/`,
  },

  {
    logoName: linkdLnImg,
    logoLink: `https://www.linkedin.com/in/developer-squad-27b0a71bb`,
  },
];

const CompanyAbout = () => {
  return (
    <section className="company_about_main_section_container">
      <div className="company_under_main_container">
        <div className="compnay_flex_container">
          <div className="company_one_container">
            <div className="company_green_container">
              <div className="company_circle_container">
                <img
                  src={ds_logo.default}
                  alt="ds_logo"
                  className="company_logo_"
                />
              </div>
              <p
                className="company_title_text"
                style={{ textTransform: "uppercase" }}
              >
                Bringing Your <br /> Ideas to Life.
              </p>
              <p className="extra_text_about_company">
                Bringing Your Ideas to life is our goal. As Your trusted
                advisor, we will make your dreams a reality. We vowed to go
                above and beyond for you and achieve the unachievable.
              </p>

              <div className="company_logo_flex_container">
                {socialLogo.map((perLogo) => {
                  return (
                    <a
                      href={perLogo.logoLink}
                      className="company_social_logo_container"
                      key={Math.random()}
                    >
                      <img src={perLogo.logoName.default} alt="logo_name" />
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="margin_container">
              <p className="about_extra_paragraph_text">
                We are <strong>team Developer Squad</strong>, a group of
                <strong>professionals</strong>, working behind
                <strong>easing</strong>, <strong>refurbishing</strong>,
                <strong>re-establishing</strong> and <strong>gearing up</strong>
                the work of the individual.
              </p>
              <p className="about_extra_paragraph_text">
                We help to bring the person's imagination to life. We've been
                working on this sort of thing for the last 2 years. To
                <strong> improve our services</strong>, we learn from our
                <strong>clients' testimonials</strong>.
              </p>
            </div>
          </div>
          <div className="company_two_container">
            <p className="about_title_text_me">ABOUT US</p>
            <div className="margin_container">
              <p className="about_extra_paragraph_text">
                <strong>What did we accomplish?</strong> <br />
                Over the years, we have <strong>worked</strong> on a
                <strong>variety of projects</strong> with rising firms,
                <strong>startups</strong>, and <strong>individuals</strong>. As
                a result of our work, we are <strong>able to</strong> make
                <strong>our customers</strong> <strong>repeat</strong>
                customers. In addition to this, we spend very
                <strong>little</strong> on <strong>marketing</strong>, and most
                of our new <strong>clients</strong> come from word-of-mouth
                <strong>referrals</strong>.
              </p>
              <br />
              <p className="about_extra_paragraph_text">
                <strong>Are we doing well?</strong> <br />
                Since we were starting out, it was hard to find a
                <strong>genuine</strong> and <strong>legitimate client</strong>,
                but we did not give up and continued to work at a low rate. In
                the past, we have found it difficult to deal with various
                problems on a personal as well as professional level. <br />
                <br />
                Following the<strong> growth of our network</strong> in
                <strong>India</strong> as well as other countries, including
                <strong>USA</strong> , <strong>Malaysia</strong>, and
                <strong>Dubai</strong>, we are now in a position to start our
                firm and expand our team. Despite still having problems, we can
                say we are doing better now.
              </p>
              <br />
            </div>
          </div>
        </div>

        <div className="two_container_about">
          <div className="first_container_desgin">
            <div className="grow_img_container">
              <img src={grow_img.default} className="grow_img" alt="ds" />
            </div>
          </div>
          <div className="first_container_desgin1">
            <p className="about_extra_paragraph_text ">
              <strong>What makes us different?</strong> <br />
              In a <strong>very short period of time</strong>, the
              <strong>Developer Squad has reached great heights</strong>, with
              an aim to reach even further.We have acquired projects from all
              over the world with little marketing, and we have always gotten
              repeat clients who know they can rely on our services.
            </p>
            <br />
            <p className="about_extra_paragraph_text ">
              Our team is <strong>learning</strong> and
              <strong>acquiring technologies</strong> along with enhancing the
              skills <strong>we already have</strong>. We <strong>aim</strong>
              to be a <strong>prominent service-based</strong> company in our
              sector. We're <strong>committed</strong> to
              <strong>providing the best service possible</strong>. Our
              <strong>mission</strong> is to <strong>learn</strong> and
              <strong>grow</strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyAbout;
