import React from "react";
import "../css files/viewproject.css";

const proj_img_1 = require("../../assets/image1.jpg");
const proj_img_2 = require("../../assets/image2.jpg");
const proj_img_3 = require("../../assets/image3.jpg");
const proj_img_4 = require("../../assets/image4.jpg");

const allProjectContent = [
  {
    hashNo: "1",
    projName: "Diet Delight",
    projDetail: `<p>A <strong>website developed</strong> for <strong>Dubai Food Business</strong>, an outsourced project to us by <strong>Vadodara IT company "Enigmaty"</strong>.</p>`,
    projLink: proj_img_3,
  },
  {
    hashNo: "2",
    projName: "Stride Ahead Landing Page",
    projDetail: `<p><strong>Stride Ahead</strong> offers <strong>AI</strong>-based <strong>career assistance</strong> to users based on the <strong>digital profile of users</strong> and provides <strong>predictive talent solutions</strong> to <strong>recruiters startup company</strong> . <strong>Landing page</strong> for Stride Ahead company was <strong>created</strong> using <strong>HTML</strong>, <strong>CSS</strong>, and <strong>React JS</strong> language.</p>`,
    projLink: proj_img_2,
  },
  {
    hashNo: "3",
    projName: "DWEC Main Website",
    projDetail: `<p><strong>Dynamic World Education Community (DWEC)</strong> is a premier <strong>overseas education</strong> facilitator located in <strong>Vadodara, Gujarat</strong>. It was <strong>our responsibility</strong> to <strong>create</strong> the main <strong>website</strong>.<strong> React JS, HTML,</strong> and <strong>CSS</strong> are all used to create it.</p>`,
    projLink: proj_img_1,
  },
];

const ViewProject = () => {
  return (
    <div className="proj_container_">
      <section className="main_gradient_container">
        <div className="main_view_project_container">
          <div className="main_title_text_container">
            <p className="text_title">
              OUR
              <span className="sub_text_title"> PROJECT</span>
            </p>
          </div>
          <div className="flex_container_proj">
            {allProjectContent.map((perProj) => {
              return (
                <div className="square_container" key={Math.random()}>
                  <div className="space_bet_container">
                    <div>
                      <p className="hash_text">#{perProj.hashNo}</p>
                      <div className="proj_img_container">
                        <img src={perProj.projLink} alt="proj_img" />
                      </div>

                      <p className="proj_name_text">{perProj.projName}</p>
                      <div
                        className="proj_extra_text"
                        dangerouslySetInnerHTML={{
                          __html: perProj.projDetail,
                        }}
                      ></div>
                    </div>

                    <a href={perProj.projLink} className="view_project_btn">
                      VIEW PROJECT
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewProject;
