import React from "react";
import "../css files/ideasec.css";
const idea_left_img = require("../../assets/work.jpg");
const idea_right_img = require("../../assets/idea-two.jpg");

function IdeaSection(props) {
  return (
    <section className="idea_main_container">
      <div className="idea_under_main_container">
        <div className="left_idea_container">
          <div className="left_img_container">
            <img
              className="idea_left_img"
              src={idea_left_img}
              alt="we-work-on-idea"
            />
          </div>

          <div className="txt_title_idea_left_container">
            <p className="text_title">
              Bringing your
              <br />
              <span className="sub_text_title">ideas to life.</span>
            </p>

            <p className="idea_extra_content">
              <strong>Bringing your ideas to life is our goal</strong>. As your{" "}
              <strong>trusted advisor</strong>, we will <strong>make</strong>{" "}
              your <strong>dreams</strong> a <strong>reality</strong>. We{" "}
              <strong>vowed</strong> to <strong>go above</strong> and{" "}
              <strong>beyond for you</strong> and <strong>achieve</strong> the
              unachievable.
            </p>
          </div>
        </div>
        <div className="right_idea_container">
          <div className="two_sqaure_img_contianer">
            <div className="right_img_container">
              <img
                className="idea_left_img"
                src={idea_right_img}
                alt="we-work-on-idea"
              />
            </div>
            <div className="one_square_right_container" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default IdeaSection;
