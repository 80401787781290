import React from "react";
import "./alert.css";

import { IoMdClose } from "react-icons/io";
import "./alert.css";

export default function AlertCustDialogBox({
  dialogHeaderName,
  children,
  onClose,
  open,
}) {
  if (open) {
    return (
      <>
        <div className="overlay_styles2" />
        <div className="modal_styles2">
          <div className="close_btn_headertext_container1">
            <h6 className="edit_dialog_header1">{dialogHeaderName}</h6>
            <button onClick={onClose} className="close_btn_dialog">
              <IoMdClose className="md_close_btn_icon1" />
            </button>
          </div>
          {children}
        </div>
      </>
    );
  } else {
    return <></>;
  }
}
