import React from "react";
import { Navigate } from "react-router-dom";
import "../css files/aboutsection.css";
import { useNavigate } from "react-router-dom";
const ds_img = require("../../assets/ds_logo_new.png");

function AboutCompanySection(props) {
  const navigate = useNavigate();
  return (
    <section className="about_the_company_main_section" id="aboutus">
      <div className="company_under_the_main_section">
        <div className="two_part_of_the_section">
          <div className="first_part_of_section">
            <div className="first_part_under_section">
              <p className="work_flow_text_heading_about">
                ABOUT <br />
                <span className="highlighted_title_text_about">
                  THE COMPANY{" "}
                </span>
              </p>

              <p className="tagline_of_company_text">
                We
                <strong> aim</strong> to be a{" "}
                <strong>prominent service-based</strong> company in our sector.
                We're <strong>committed</strong> to{" "}
                <strong>providing the best service possible</strong>.
              </p>

              <p className="description_of_about_paragraph">
                We are <strong>Developer Squad</strong>, an
                <strong> emerging software development</strong> firm playing a
                vital role in the area. Creating a
                <strong> one-stop solution</strong> for
                <strong> businesses</strong> is our <strong> goal</strong> as a
                <strong> group of ambitious</strong> , <strong> energetic </strong>
                 and <strong> enthusiastic individuals</strong>. We
                <strong> manage</strong> everything for you from
                <strong> designing</strong> to <strong>development</strong>, to
                <strong> hosting</strong> your product and
                <strong> optimizing</strong> it for search engines (
                <strong>SEO</strong>). As a <strong>startup</strong>, we strive
                to <strong>provide</strong> the
                <strong> finest possible service</strong> to our
                <strong> customers</strong> to <strong>outperform</strong> our
                <strong> competitors</strong>. A commitment to educating
                ourselves and <strong>adapting</strong> to new
                <strong> technologies</strong> to achieve
                <strong> excellent results</strong> for our
                <strong> clients</strong>.
              </p>

              <button
                className="read_more_btn_about_company"
                onClick={() => navigate("/AboutusMain")}
              >
                READ MORE
              </button>
            </div>
          </div>
          <div className="sec_part_of_section">
            <div className="sec_part_under_section">
              <p className="our_vision_text">OUR VISION</p>

              <div className="all_vision_content_container">
                <li>
                  <span>Adapting and changing as the world changes</span>
                </li>
                <li>
                  <span>Continue to learn and reinvent.</span>
                </li>
                <li>
                  <span>Globalizing business</span>
                </li>
                <li>
                  <span>Expanding the team</span>
                </li>
                <li>
                  <span>Expanding our network</span>
                </li>
                <li>
                  <span>A friendly work environment</span>
                </li>
              </div>

              <div className="about_us_img_container">
                <div className="about_ds_img_container">
                  <img src={ds_img} alt="ds" className="ds_img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutCompanySection;
