import React, { useEffect, useState } from "react";
// import ContactUsFormPart from "../components/ContactUsFormPart";
import "../pages/css files/footer.css";
import { contactUs } from "../service/api";
import { Link, NavLink } from "react-router-dom";
import CustDialogBox from "./custdialogbox/CustDialogBox";
import ContactUsFormPart from "./ContactUsFormPart";

const Footer = () => {
  const [toggleModal, setToggleModal] = useState(false);
  const handleApply = () => {
    setToggleModal(true);
  };
  return (
    <>
      <section className="footer_main_contaier" id="contactus">
        <div className="footer_under_main_contaier">
          <div className="footer_all_two_part_contaienr">
            <div className="footer_first_part_container">
              <div>
                <p className="title_text_footer">CONTACT NO.</p>
                <a href="tel:+9726609747" className="subtitle_text_footer">
                  +91 9726609747
                </a>
              </div>
              <div>
                <p className="title_text_footer">EMAIL</p>
                <a
                  href="mailto: developersquad575@gmail.com"
                  className="subtitle_text_footer"
                >
                  developersquad575@gmail.com
                </a>
              </div>
              <div>
                <p className="title_text_footer">ADDRESS</p>
                <p
                  href="mailto: developersquad575@gmail.com"
                  className="subtitle_text_footer"
                >
                  India
                </p>
              </div>
            </div>
            <div className="footer_sec_part_container">
              <div>
                <p className="title_text_footer">NAVIGATION</p>
                <NavLink to="/" className="subtitle_text_footer">
                  Home
                </NavLink>
                <br />
                <NavLink to="/ServiceMainPage" className="subtitle_text_footer">
                  Service
                </NavLink>
                <br />
                <NavLink to="/WorkMain" className="subtitle_text_footer">
                  Our Work
                </NavLink>
                <br />
                <NavLink to="/AboutusMain" className="subtitle_text_footer">
                  About Us
                </NavLink>
                <br />
                <NavLink to="/ContactUs" className="subtitle_text_footer">
                  Contact Us
                </NavLink>
              </div>
            </div>

            <div className="footer_sec_part_container">
              <p className="title_text_footer">SITEMAP</p>
              <Link to="/privacypolicy" className="subtitle_text_footer">
                Privacy Policy
              </Link>

              <Link to="/terms" className="subtitle_text_footer">
                Terms & Conditions
              </Link>
              <Link to="/CareerMain" className="subtitle_text_footer">
                Careers
              </Link>
            </div>
            <div className="footer_sec_part_container">
              <p className="title_text_footer">
                FOR ANY BUSINESS <br /> INQUIRY
              </p>
              <button onClick={handleApply}>Apply Now</button>
            </div>
          </div>
          <p className="title_text_footer align_text">
            CREATED BY : DEVELOPER SQUAD
          </p>
        </div>
      </section>
      <CustDialogBox
        dialogHeaderName="Contact Us"
        open={toggleModal}
        onClose={() => {
          setToggleModal(false);
        }}
      >
        <div className="form_modal_container">
          <ContactUsFormPart />
        </div>
      </CustDialogBox>
    </>
  );
};

export default Footer;
