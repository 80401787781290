import React, { useEffect, useState } from "react";
import "../../css files/applynowform.css";
import InputTextBox from "./InputTextBox";
import { useLocation } from "react-router-dom";

function ApplyNowForm(props) {
  const [allEntryValue, setAllEntryValue] = useState({
    fname: "",
    course: "",
    score: "",
    experience: "",
    location: "",
    date: "",
    pkg: "",
    skill: "",
  });

  const { state } = useLocation();

  useEffect(() => {}, [state]);

  const handleOnChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAllEntryValue({ ...allEntryValue, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(allEntryValue);

    setAllEntryValue({
      fname: "",
      course: "",
      score: "",
      experience: "",
      location: "",
      date: "",
      pkg: "",
      skill: "",
    });

    // setToggleAlertModal(true);
    props.closeDialog(false);
  };

  return (
    <form className="apply_now_form_container" onSubmit={onSubmit}>
      <InputTextBox
        labelName="Full name"
        inputType="text"
        placeholderText="Enter full name"
        inputId="fname"
        nameText="fname"
        value={allEntryValue.fname}
        onChange={handleOnChange}
      />

      <InputTextBox
        labelName="Last Education course"
        inputType="text"
        placeholderText="Enter (Example. BE)"
        inputId="course"
        nameText="course"
        value={allEntryValue.course}
        onChange={handleOnChange}
      />

      <InputTextBox
        labelName="Last Education score"
        inputType="text"
        placeholderText="Enter last Education score (Example. CGPA 8)"
        inputId="score"
        nameText="score"
        value={allEntryValue.score}
        onChange={handleOnChange}
      />

      <InputTextBox
        labelName="How many experience in this field ?"
        inputType="text"
        placeholderText="Enter (Example. 2 years)"
        inputId="experience"
        nameText="experience"
        value={allEntryValue.experience}
        onChange={handleOnChange}
      />

      <InputTextBox
        labelName="What is your current location ?"
        inputType="text"
        placeholderText="Enter location (Example. Surat)"
        inputId="location"
        nameText="location"
        value={allEntryValue.location}
        onChange={handleOnChange}
      />

      <InputTextBox
        labelName="What is your expected joining date ?"
        inputType="date"
        placeholderText="Enter date"
        inputId="date"
        nameText="date"
        value={allEntryValue.date}
        onChange={handleOnChange}
      />

      <InputTextBox
        labelName="What is your expected annual package ?"
        inputType="text"
        placeholderText="Enter (Example: 2 - 3 lakh)"
        inputId="pkg"
        nameText="pkg"
        value={allEntryValue.pkg}
        onChange={handleOnChange}
      />

      <div className="input_label_field_container">
        <label>Skills</label>
        <textarea
          className="text_area_form"
          id="skill"
          name="skill"
          rows="3"
          cols="50"
          placeholder=""
          value={allEntryValue.skill}
          onChange={handleOnChange}
        />
      </div>

      <button className="submit_btn_apply">Submit</button>
    </form>
  );
}

export default ApplyNowForm;
