import React, { useEffect, useState } from "react";
import DevelopmentSection from "./DevelopmentSection";
import IdeaSection from "./IdeaSection";
import Service from "./Service";
import WorkFlowSection from "./WorkFlowSection";
import LanguageSliderSection from "./LanguageSliderSection";
import AboutCompanySection from "./AboutCompanySection";
import TestimonialSection from "./TestimonialSection";
import ProjectSection from "./ProjectSection";
import SocialLinkSection from "./SocialLinkSection";
import Footer from "../../components/Footer";

import CustDialogBox from "../../components/custdialogbox/CustDialogBox";
import "../css files/fixbtn.css";
import { MdCall } from "react-icons/md";
import ContactUsFormPart from "../../components/ContactUsFormPart";
import FixButton from "../../components/FixButton";

function Home(props) {
  // const [showBtn, setShowBtn] = useState(false);
  // const [toggleModal, setToggleModal] = useState(false);
  // const [openInterestedForm, setOpenInterestedForm] = useState(false);

  // function dialog(e) {
  //   setToggleModal(e);
  // }

  return (
    <div className="main_page_container">
      <DevelopmentSection />

      <IdeaSection />
      <Service />
      <WorkFlowSection />
      <LanguageSliderSection />
      <AboutCompanySection />

      <TestimonialSection />

      <ProjectSection />
      <SocialLinkSection />
      {/* <button
        className="fix_btn_container"
        onMouseEnter={() => setShowBtn(true)}
        onMouseLeave={() => setShowBtn(false)}
        onClick={() => setToggleModal(true)}
      >
        {showBtn ? (
          <p className="fix_text">
            Contact Us <MdCall />
          </p>
        ) : (
          <p className="fix_text">
            <MdCall />
          </p>
        )}
      </button> */}
      {/* <FixButton /> */}
      {/* <CustDialogBox
        dialogHeaderName="Contact Us"
        open={toggleModal}
        onClose={() => {
          setToggleModal(false);
        }}
      >
        <div className="form_modal_container">
          <ContactUsFormPart />
        </div>
      </CustDialogBox> */}
      <Footer />
    </div>
  );
}

export default Home;
