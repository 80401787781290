import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import ProjectHeroSection from "./ProjectHeroSection";
import ViewProject from "./ViewProject";

import CustDialogBox from "../../components/custdialogbox/CustDialogBox";
import "../css files/fixbtn.css";
import { MdCall } from "react-icons/md";
import ContactUsFormPart from "../../components/ContactUsFormPart";
import FixButton from "../../components/FixButton";

const ProjectMain = () => {
  // const [showBtn, setShowBtn] = useState(false);
  // const [toggleModal, setToggleModal] = useState(false);
  // const [openInterestedForm, setOpenInterestedForm] = useState(false);

  return (
    <>
      <ProjectHeroSection />
      <ViewProject />
      {/* <button
        className="fix_btn_container"
        onMouseEnter={() => setShowBtn(true)}
        onMouseLeave={() => setShowBtn(false)}
        onClick={() => setToggleModal(true)}
      >
        {showBtn ? (
          <p className="fix_text">
            Contact Us <MdCall />
          </p>
        ) : (
          <p className="fix_text">
            <MdCall />
          </p>
        )}
      </button>
      <CustDialogBox
        dialogHeaderName="Contact Us"
        open={toggleModal}
        onClose={() => {
          setToggleModal(false);
        }}
      >
        <div className="form_modal_container">
          <ContactUsFormPart />
        </div>
      </CustDialogBox> */}
      {/* <FixButton /> */}
      <Footer />
    </>
  );
};

export default ProjectMain;
