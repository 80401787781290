import React from "react";
import "../css files/testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import "./styles.css";
import Carousel from "react-elastic-carousel";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
const bg_testimonial = require("../../assets/testimonial_bg.jpg");
const logo1 = require("../../assets/companylogo/size/nc.png");
const logo6 = require("../../assets/companylogo/size/indigital.png");
const logo3 = require("../../assets/companylogo/size/stride.png");
const logo2 = require("../../assets/companylogo/size/dwec.png");
const logo4 = require("../../assets/companylogo/size/wealth.png");
const logo5 = require("../../assets/companylogo/size/osc.png");
const testimonial_left_img = require("../../assets/img_testimonial_left_side.svg");
const quote1 = require("../../assets/quote 1.svg");
const quote2 = require("../../assets/quote 2.svg");
const profile_img = require("../../assets/testimonial/Ellipse 9.png");
const profile_img1 = require("../../assets/testimonial/pujasaluja 1.png");
const profile_img2 = require("../../assets/testimonial/piyush.png");

const allLogoContent = [
  {
    logoName: logo1,
  },
  {
    logoName: logo2,
  },
  {
    logoName: logo3,
  },
  // {
  //   logoName: logo4,
  // },
  {
    logoName: logo5,
  },
  {
    logoName: logo6,
  },
];

const allRenderTestimonialContent = [
  {
    profileImgName: profile_img1,
    nameOfTestimonial: "Mrs. Puja Saluja",
    subNameOfTestimonial: "FOUNDER & CEO Of DWEC",
    extraContentTestimonial: `Just want to say a massive thank you to Developer Squad and Specially to Virendrasinh Parmar . From start to finish tou have made the whole process of building our website a pleasure. Your strength as a UI designer and development is commendable and we look forward to keep working with you and your team`,
  },
  {
    profileImgName: profile_img2,
    nameOfTestimonial: "Mr. Piyush Gupta",
    subNameOfTestimonial: "FOUNDER & CEO of Stride Ahead",
    extraContentTestimonial: `Harshiv Patel from team Developer Squad was involved in
    the development of a React-native application named “Transtutor”. “Developer Squad” ensured that we received support and timely
    delivery. The developer was always readily available and was flexible in approaching
    problems, showed a great attitude toward finding solutions, and came across as friendly. It
    would be a pleasure to work with them in the future. It's
    is an organization I recommend working with.
    `,
  },
  // {
  //   profileImgName: profile_img,
  //   nameOfTestimonial: "Esther Howard",
  //   subNameOfTestimonial: "CEO of Colgate",
  //   extraContentTestimonial: `I want to talk to you about your work on this last project
  //   because your delay oadblocks more easily.I’ d love to see you
  //   broactive in spotting them before they impact your.`,
  // },
];

function Service(props) {
  const customSlider = React.useRef();
  const renderTestimonialContent = allRenderTestimonialContent.map(
    (perTestimonialContent) => {
      return (
        <SwiperSlide>
          <div
            className="right_testimonial_under_content_container"
            key={Math.random()}
          >
            <div className="left_right_quote_and_img_container">
              <div className="first_quote_img_container">
                <img src={quote1.default} alt="quote" />
              </div>
              <div className="profile_img_testimonial_container">
                <img
                  src={perTestimonialContent.profileImgName}
                  alt="ds_testimonial_img"
                />
              </div>
              <div className="first_quote_img_container">
                <img src={quote2.default} alt="quote" />
              </div>
            </div>
            <div className="text_of_testimonial_content">
              <strong className="name_of_user_text">
                {" "}
                {perTestimonialContent.nameOfTestimonial}{" "}
              </strong>
              <p className="tagline_of_user_content">
                {" "}
                {perTestimonialContent.subNameOfTestimonial}
              </p>
              <p className="extra_content_of_text">
                “{perTestimonialContent.extraContentTestimonial}“
              </p>
            </div>
          </div>
        </SwiperSlide>
      );
    }
  );

  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: true },
    { width: 550, itemsToShow: 1, itemsToScroll: 1, pagination: true },
    { width: 850, itemsToShow: 1, pagination: true },
    { width: 1000, itemsToShow: 1, pagination: true },
  ];
  return (
    <div className="testimonial_main_container" id="testimonial">
      <div className="testimonial_under_main_container">
        <div className="img_bg_heading_section">
          <p className="testimonial_text_heading"> TESTIMONIAL </p>
        </div>
        <div className="grey_bg_container">
          <div className="two_container_of_testimonial_section">
            <div className="left_testimonial_section">
              <div className="left_img_testimonial_container">
                <img src={testimonial_left_img.default} alt="testimonial_img" />
              </div>
            </div>
            <div className="right_testimonial_section">
              <Swiper
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                grabCursor={true}
                // loop={true}
                keyboard={true}
                modules={[Navigation, Pagination, Keyboard]}
                className="mySwiper"
              >
                {renderTestimonialContent}
              </Swiper>
              {/* <Carousel
                breakPoints={breakPoints}
                ref={customSlider}
                enableAutoPlay={true}
                showArrows={true}
                autoPlaySpeed={10000}
              > */}
              {/* {renderTestimonialContent} */}
              {/* </Carousel> */}
            </div>
          </div>
          <div className="logo_container">
            {allLogoContent.map((perLogoContent) => {
              return (
                <div className="logo_dwec_container">
                  <img
                    src={perLogoContent.logoName}
                    alt="ds_company_logo"
                    loading="lazy"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Service;
