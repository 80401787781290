import React, { useEffect, useState } from "react";
import "./alert.css";
const alert_vdo = require("../../assets/loader_success.gif");

function Alertbox(props) {
  return (
    <div className="alert_box_main_container">
      <div className="square_box_success_container">
        <img src={alert_vdo} alt="alert" />
      </div>
      <strong>Your details has been saved </strong>
    </div>
  );
}

export default Alertbox;
