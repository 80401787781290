import React from "react";
import "../css files/service.css";
import { Link, useNavigate } from "react-router-dom";

const allServiceItems = [
  {
    headingServiceName: "APP DEVELOPMENT",
    extraTextServiceName: `Nearly half of the companies have registered their business on the fingertip of your mobile
    phone, and many of them have plans to establish one.
    Our service helps you do the same.
    As the datas say nearly half of the companies have registered their business on the fingertouch
    of your mobile phones`,
    headerReadMore: `<p>APPLICATION<br/>DEVELOPMENT</p>`,
    firstParagraph: `<p><strong>Nearly</strong> half of the <strong>companies</strong> have registered their <strong>business on</strong> the <strong>fingertip</strong> of your <strong>mobile phone</strong>, and many of them have plans to establish one.<br /> Our service helps you do the same. The <strong>expertise</strong> of our team lies in <strong>building hybrid mobile applications</strong> with the <strong>ease of use</strong> and <strong>best</strong> in class <strong>user experiences</strong> that cover your businesses with automation.</p>`,
    secParagraph: `<p>Throughout the years, we have <strong>worked</strong> with many <strong>reputed companies</strong><br />and <strong>dynamic startups</strong> to ensure that their functions run <strong>smoothly as butter</strong>. We have <strong>helped</strong> to <strong>build</strong> mobile apps covering a wide range of themes&nbsp; such as <strong>productivity mobile apps</strong>, <strong>Edtech</strong> mobile apps, <strong>Lifestyle</strong> mobile apps. But that doesn't mean we limit ourselves here. We have a <strong>dynamic team</strong> that is ready to apply <strong>research</strong>, <strong>guidance</strong>, and <strong>determination</strong> to <strong>any challenge</strong>.</p>`,
    thirdParagraph: `<p>Perhaps you are <strong>wondering</strong> what a <strong>hybrid mobile application</strong> is?</p>
    <p><br />As there are <strong>multiple OS</strong>(Operating Systems) Platforms available in the market, such as <strong>Android</strong>, <strong>iOS</strong>, and W<strong>indows</strong> in the past it was necessary to <strong>develop applications</strong> in their <strong>native languages</strong> to run on specific platforms, which entails a lot of time, expenses and platform specific native developer. Thankfully, there was an answer in the form of hybrid applications developed on top of languages such as <strong>Javascript (React-native)</strong> and <strong>Dart (Flutter)</strong> that could run on platforms such as Android, iOS, and the web developed by <strong>Facebook</strong> and <strong>Google</strong> respectively. Despite the <strong>fact</strong> that <strong>React-native came to market early</strong>, it has a <strong>bigger community than Flutter</strong>. However, <strong>both</strong> of these <strong>technologies</strong> have a <strong>strong hold</strong> and are <strong>growing</strong>.</p>
    <p>Then you may have thought, are we really going to do away with native languages like <strong>Android</strong> and <strong>Swift</strong>?</p>
    <p>There is <strong>no doubt that hybrid technologies have their best use-cases and strong holdings</strong>, <strong>but</strong> they may be <strong>slow to handle large-scale</strong> applications with <strong>high thread counts</strong> no doubt these hybrid technologies have their best use-cases and strong holdings but they may work slow on running the high threads work. Due to the fact that these technologies on back of the story work with an extra layer between the OS and the source code, <strong>hybrid applications</strong> rely on <strong>mobile browser speed</strong>, and there are some <strong>potential UI</strong> limitations as it <strong>fails</strong> to offer <strong>native-like feeling</strong>.</p>
    <p><br /><strong>Now you may think then what should we do??</strong></p>
    <p><br />Nowadays, <strong>hybrid technologies have grown stronger</strong> and have a <strong>growing performance scale day by day</strong>. If you have a<strong> small or medium-sized mobile</strong> application,<strong> hybrid applications are the best option</strong>, but if you have a more robust and resource intensive application that consumes more threads,<strong> native applications are the best approach.</strong></p>`,
  },
  {
    headingServiceName: "WEB DEVELOPMENT",
    extraTextServiceName: `Statistics suggest that more then 60% of market sectors already have websites
    for their business, and the present scenario shows that without a proper nad
    better web application, it is not possible to take your business to the top.
    We make sure you get what you need`,
    headerReadMore: `<p>WEBSITE<br/>DEVELOPMENT</p>`,
    firstParagraph: `<p>Statistics suggest that more then 60% of market sectors already have websites for their business, and the present scenario shows that without a proper and better web application, it is not possible to take your business to the top. We make sure you get what you need when our team is at your service, so you don't have to worry. Our team consists of experts in these areas. We <strong>develop websites</strong> of all kinds, including <strong>e-commerce websites, business websites, portfolio websites, blogs, as well as personal and membership</strong> websites. We have excelled in building <strong>Single page Application(SPA)</strong> apart from regular website.</p>`,
    secParagraph: ``,
    thirdParagraph: `<p><strong>Single Page Applications: What are they?</strong></p>
    <p><br />A single page application serves the same purpose as a regular websites. The concept is named so because there is only one index.html page and all other pages are rendered on this particular page and are known as classes, functions or components.</p>
    <p>&nbsp;</p>
    <p><strong>Why is it beneficial to have it?</strong></p>
    <p><br />The Single page applications(SPA) have the advantage of eliminating<br />unnecessary re-rendering of pages and preventing excessive requests<br />to the server. In addition to these features, they also render rapidly,<br />so your customers can get the best, most effective and attractive<br />user interfaces.</p>`,
  },
  {
    headingServiceName: "API DEVELOPMENT",
    extraTextServiceName: `We have a team that specializes in these areas, It is one of the key
    elements in the development process. We prefer Stand-alone front-end and
    back-end applications, and Api is what connects them. To Date, we have
    created more than 1000 APIs and have served more than six to se`,
    headerReadMore: `<p>API<br/>DEVELOPMENT</p>`,
    firstParagraph: `<p>We have a team that specializes in these areas, It is one of the key<br />elements in the development process. We prefer stand-alone front-end and back-end applications, and API is what connects them. To Date, we have created more than 1000 APIs and have served more than six to seven applications and websites. Creating public and protected apis is what we excel in.</p>`,
    secParagraph: `<p><strong>API - What does it Stand For??</strong>Api stands for <strong>Application Programming Interface. </strong>Don't worry, it's not as difficult as it sounds. Can i make it easier for you? Api simply act as an intermediary, serving you as you order from the menu. Anything not in the syllabus is a 404 error. Bridges the gap between front-end and back-end. Provides services to customers based on their requests.</p>`,
    thirdParagraph: `<p><strong>What is the process of developing APIs?</strong></p>
    <p><br /><strong>APIs can be built</strong> using any <strong>backend language</strong> that you prefer, including <strong>PHP, Python, Nodejs</strong>, or any other. Keep in mind to choose wisely. Understand what the front-end application is asking for and accordingly provide it with data with proper path to which the front-end developer can request to and gets what is required.</p>
    <p><br /><strong>Does testing my APIs require a front-end application?</strong></p>
    <p><br />There are tools such as <strong>Postman</strong> and <strong>Swagger</strong> that can be used to testand run APIs, as well as to create API documentation, which is referred by front-end developers.</p>`,
  },
  {
    headingServiceName: "WEB DESIGNING",
    extraTextServiceName: `The design of a website is largely determined by how it looks, how
    attractive it is, how interactive it is, and how it attracts people to
    spend time on your site and buy what you are selling, read what you are
    writing, see what you are showing, feels how you want it to be, by representing
    it in a way that most of the users desire.`,
    headerReadMore: `<p>WEBSITE<br/>DESIGNING</p>`,
    firstParagraph: `<p>The<strong> design of a website</strong> is largely <strong>determined</strong> by <strong>how it looks</strong>, how<br /><strong>attractive</strong> it is, how <strong>interactive</strong> it is, and how it <strong>attracts people to</strong><br /><strong>spend time on your site</strong> and buy what you are <strong>selling</strong>, read what you are writing, see what you are <strong>showing</strong>, feels how you want it to be, by representing it in a way that most of the users desire. As a team, we have built and designed over dozens of websites so far</p>`,
    secParagraph: `<p>As a web designer, you always need to focus on the words <strong>UI/ UX</strong><br />UI stands for user interface, and UX is similarly for user experience.<br />Make sure that the user can easily use and understand and read the website content Ensure that the website content can be understood, used and read easily by the user and that the user won't get bored and will find what he's looking for easily , and make sure he has one of his best experiences reading it.</p>`,
    thirdParagraph: ``,
  },

  {
    headingServiceName: "RESPONSIVE DESIGN",
    extraTextServiceName: `Now that the markets is shifting digitally, the majority of consumers use
    mobile devices, and there are a variety of devices with different screen
    resolutions, and with different os and browsers. It is imperative that they all see and feel things
    the same
    way, as they are expected to.
    We have designed the responsive UIs for mobile and web applications for different
    screen dimensions and for different platform`,
    headerReadMore: `<p>RESPONSIVE<br/>DESIGN</p>`,
    firstParagraph: `<p>Now that the markets is shifting digitally, the majority of consumers use mobile devices, and there are a variety of devices with different screen resolutions, and with different OS and browsers. It is imperative that they all see and feel things the same away, as they are expected to. We have designed the responsive UIs for mobile and web applications for different screen dimensions and for different platforms.</p>`,
    secParagraph: ``,
    thirdParagraph: `<p><strong>Does this mean i need to write the code separately for each screen </strong><strong>resolutions?</strong></p>
    <p><br />It's not a yes or no. What you need to do here is take a majority of the screen resolutions that are generally used and declare that in the web application's media query and then rewrite the classes that should be considered specifically for the resolutions. And for Mobile applications it depends on what technology you choose.</p>`,
  },
  {
    headingServiceName: "BACKEND DEVELOPMENT",
    extraTextServiceName: `The backend application is mostly responsible for things like database construction,
    managing data, storing data, making the data available when requested,
    and altering data when required. Furthermore, it's the part where the main
    algorithm logic is built and implemented, as well as to create APIs that front-end
    developers can use to interact with the back-end`,
    headerReadMore: `<p>BACKEND<br/>DEVELOPMENT</p>`,
    firstParagraph: `<p>The <strong>backend application</strong> is mostly <strong>responsible</strong> for things like database construction,managing data, storing data, making the data available when requested,and altering data when required. Furthermore, it's the part where the main algorithm logic is built and implemented, as well as to create APIs that front-end developers can use to interact with the back-end.</p>`,
    secParagraph: `<p>Our backend support team has worked on number of mobile and web applications. We are dedicated to ensuring the smooth operation of our users business backbone so they can focus on what's most important to them.</p>`,
    thirdParagraph: `<p><strong>How can i become Backend developer?</strong></p>
    <p><br />You should excel at any of the backend languages like Node JS, PHP, Python, etc. Keep in mind how far you can go with the technology. Additionally, if you're looking for a job, keep in mind what are the potential job opportunities and what the future holds for each technology</p>`,
  },
  {
    headingServiceName: "SEO MANAGEMENT",
    extraTextServiceName: `We are the best SEO company (Search Engine Optimisation), the most productive and 100% result-oriented online marketing strategy for your business. This is because SEO Marketing is an organic art of combining “Keyword Rich Content” + “Quality Link Building”.`,
    headerReadMore: `<p>SEO<br/>MANAGEMENT</p>`,
    firstParagraph: `<p>If you own, manage, monetize, or promote online content via Google Search, this guide is meant for you. You might be the owner of a growing and thriving business, the website owner of a dozen sites, the SEO specialist in a web agency or a DIY SEO expert passionate about the mechanics of Search: this guide is meant for you. If you're interested in having a complete overview of the basics of SEO according to our best practices, you are indeed in the right place.</p>`,
    secParagraph: `<p>Search engine optimization (SEO) is often about making small modifications to parts of your website. When viewed individually, these changes might seem like incremental improvements, but when combined with other optimizations, they could have a noticeable impact on your site's user experience and performance in organic search results. You're likely already familiar with many of the topics in this guide, because they're essential ingredients for any web page, but you may not be making the most out of them.</p>`,
    thirdParagraph: `<p>You should build a website to benefit your users, and gear any optimization toward making the user experience better. One of those users is a search engine, which helps other users discover your content. SEO is about helping search engines understand and present content. Your site may be smaller or larger than our example site and offer vastly different content, but the optimization topics in this guide applies to sites of all sizes and types.</p>`,
  },
];

function Service(props) {
  const navigate = useNavigate();
  const handleReadMore = (item) => {
    navigate("/ReadMoreService", { state: item });
  };
  return (
    <div className="service_main_container" id="services">
      <div className="service_under_main_container">
        <p className="our_service_text_title">OUR SERVICES</p>
        <div className="service_all_box_container">
          {allServiceItems.map((perServiceItem) => {
            return (
              <div className="service_box" key={Math.random()}>
                <h6 className="heading_text_service">
                  {perServiceItem.headingServiceName}
                </h6>
                <p className="extra_text_service">
                  {perServiceItem.extraTextServiceName.length > 125
                    ? perServiceItem.extraTextServiceName.substring(0, 125)
                    : perServiceItem.extraTextServiceName}
                  <span>&nbsp;</span>
                  <button
                    onClick={() => handleReadMore(perServiceItem)}
                    // to="/ReadMoreService"
                    className="read_more_btn_style"
                  >
                    {" "}
                    Read more ...
                  </button>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Service;
