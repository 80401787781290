import React, { useEffect, useState } from "react";
import { MdOutlineMenu } from "react-icons/md";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import "../css files/developmentsec.css";
import "../css files/workherosection.css";
import "../css files/projectherosection.css";

const developer_squad_logo = require("../../assets/ds_only_text.png");

function ProjectHeroSection(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuBtn = () => {
    console.log("menu");
    setMenuOpen(true);

    document.body.style.backgroundColor = "rgba(0,0,0,1)";
  };

  const handleMenuClose = () => {
    console.log("close");
    setMenuOpen(false);
  };
  return (
    <section className="bg_section_container1">
      <div className="bg_hero_section_container">
        <div className="nav_bar_under_text_container">
          <Navbar />

          <div className="heading_hero_section_container">
            <div className="green_container_heading_projects">
              <div className="work_title_text_container">
                <p className="our_work_title_text">PROJECTS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* menu */}
    </section>
  );
}

export default ProjectHeroSection;
