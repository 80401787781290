import React, { useEffect, useState } from "react";
import "../css files/sociallinksection.css";
const social_img = require("../../assets/youtube.png");
const social_img1 = require("../../assets/insta.png");
const social_img2 = require("../../assets/g_business.png");
const social_img3 = require("../../assets/fb.png");

const social_img4 = require("../../assets/gmail.png");
const social_img5 = require("../../assets/linkedin.png");
const hover_img = require("../../assets/yt_hover.png");
const hover_img1 = require("../../assets/insta_hover.png");
const hover_img2 = require("../../assets/hover_google.png");
const hover_img3 = require("../../assets/fb_hover.png");
const hover_img4 = require("../../assets/gmail_hover.png");
const hover_img5 = require("../../assets/linkedin_hover.png");

const allSocialLogo = [
  {
    socialImgName: social_img,
    socialLinkName: `https://m.youtube.com/channel/UC6jsGYcS1GlJsGFnNFAB5Jw`,
    hoverSocialImg: hover_img,
  },
  {
    socialImgName: social_img1,
    socialLinkName: `https://www.instagram.com/developersquad.in/`,
    hoverSocialImg: hover_img1,
  },
  {
    socialImgName: social_img2,
    socialLinkName: `https://g.page/r/CTPz22E7HHbHEAc`,
    hoverSocialImg: hover_img2,
  },
  {
    socialImgName: social_img3,
    socialLinkName: `https://www.facebook.com/developersquad.in/`,
    hoverSocialImg: hover_img3,
  },
  {
    socialImgName: social_img4,
    socialLinkName: `mailto:developersquad575@gmail.com`,
    hoverSocialImg: hover_img4,
  },
  {
    socialImgName: social_img5,
    socialLinkName: `https://www.linkedin.com/in/developer-squad-27b0a71bb`,
    hoverSocialImg: hover_img5,
  },
];

const SocialLinkSection = () => {
  const [hoverIcon, setHoverIcon] = useState(false);
  const [hoverValue, setHoverValue] = useState(false);

  return (
    <section className="social_link_main_section_container">
      <div className="social_link_under_main_container">
        <div className="social_link_all_content_container">
          {allSocialLogo.map((perLogoContent) => {
            return (
              <div
                onMouseEnter={() => {
                  setHoverIcon(true);
                  setHoverValue(perLogoContent.socialImgName);
                }}
                onMouseLeave={() => {
                  setHoverValue(perLogoContent.socialImgName);
                  setHoverIcon(false);
                }}
                key={Math.random()}
              >
                {hoverIcon && hoverValue === perLogoContent.socialImgName ? (
                  <a
                    href={perLogoContent.socialLinkName}
                    className="social_link_img_container"
                  >
                    <img src={perLogoContent.hoverSocialImg} alt="social_img" />
                  </a>
                ) : (
                  <a
                    href={perLogoContent.socialLinkName}
                    className="social_link_img_container"
                  >
                    <img src={perLogoContent.socialImgName} alt="social_img" />
                  </a>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default SocialLinkSection;
