import React from "react";
import "../css files/displayworksection.css";
import SliderImage from "./SliderImage";
import CardNews from "./CardNews";
const mob_img1 = require("../../assets/work/mob1.png");
const mob_img2 = require("../../assets/work/mob2.png");
const mob_img3 = require("../../assets/work/mob3.png");

const DisplayWorkSection = () => {
  return (
    <section className="display_work_section_main_container">
      <div className="under_sec_bg_container">
        <div className="display_under_work_section_container">
          <div className="display_heading_title_container">
            <p className="work_flow_text_heading_about">
              <span className="highlighted_title_text_about">RESPONSIVE</span>{" "}
              DESIGN
              <br />
              FOR ANY{" "}
              <span className="highlighted_title_text_about">WEBSITE</span>
            </p>

            <p className="extra_work_text">
              The following are some of our most noteworthy works done for
              different kinds of companies and devices with different screen
              resolutions. Progress is being made on some of the works. A{" "}
              <strong>majority</strong> of the <strong>projects</strong> are{" "}
              <strong>built</strong> using <strong>React Js</strong> and{" "}
              <strong>Vue Js</strong>.
            </p>
          </div>

          <SliderImage />

          <div className="headaer_hybrid_text_container">
            <p className="work_flow_text_heading_about">
              <span className="highlighted_title_text_about">HYBRID</span> (IOS
              & ANDROID) <br /> MOBILE
              <span className="highlighted_title_text_about"> APPLICATION</span>
            </p>
          </div>

          <CardNews />
          {/* <div className="mobiles_img_container">
            <div className="first_slot_container">
              <div className="mobile_first_img_container">
                <img src={mob_img1} alt="mob_img1" className="mob_img_one" />
              </div>
            </div>
            <div className="first_slot_container">
              <div className="mobile_sec_img_container">
                <img src={mob_img2} alt="mob_img1" className="mob_img_one" />
              </div>
            </div>

            <div className="first_slot_container">
              <div className="mobile_third_img_container">
                <img src={mob_img3} alt="mob_img1" className="mob_img_one" />
              </div>
            </div>
          </div> */}

          <p className="extra_work_text">
            <span style={{ fontFamily: "PoppinsRegular" }}>
              Here are some of the walkthroughs on{" "}
              <strong>our ongoing projects</strong> and{" "}
              <strong>live projects</strong> on the market. The projects are
              intended to <strong>run</strong> on <strong>any platform</strong>{" "}
              (<strong>Android</strong> and <strong>IOS</strong>) utilizing{" "}
              <strong>React-native</strong>.
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default DisplayWorkSection;
