import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import ContactUsForm from "./ContactUsForm";
import GetInTouch from "./GetInTouch";

import CustDialogBox from "../../components/custdialogbox/CustDialogBox";
import "../css files/fixbtn.css";
import { MdCall } from "react-icons/md";
import ContactUsFormPart from "../../components/ContactUsFormPart";

function ContactUs(props) {
  // const [showBtn, setShowBtn] = useState(false);
  // const [toggleModal, setToggleModal] = useState(false);
  // const [openInterestedForm, setOpenInterestedForm] = useState(false);
  return (
    <>
      <section className="bg_section_container1">
        <div className="bg_hero_section_container">
          <div className="nav_bar_under_text_container">
            <Navbar />

            <div className="heading_hero_section_container">
              <div className="green_container_heading">
                <div className="work_title_text_container">
                  <p className="our_work_title_text">CONTACT US</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* menu */}
      </section>
      <GetInTouch />
      <ContactUsForm />
      {/* <button
        className="fix_btn_container"
        onMouseEnter={() => setShowBtn(true)}
        onMouseLeave={() => setShowBtn(false)}
        onClick={() => setToggleModal(true)}
      >
        {showBtn ? (
          <p className="fix_text">
            Contact Us <MdCall />
          </p>
        ) : (
          <p className="fix_text">
            <MdCall />
          </p>
        )}
      </button>
      <CustDialogBox
        dialogHeaderName="Contact Us"
        open={toggleModal}
        onClose={() => {
          setToggleModal(false);
        }}
      >
        <div className="form_modal_container">
          <ContactUsFormPart />
        </div>
      </CustDialogBox> */}
      <Footer />
    </>
  );
}

export default ContactUs;
