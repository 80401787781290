import React, { useEffect, useState } from "react";
import "../../css files/careermain.css";
import Navbar from "../../../components/Navbar";
import "../../css files/joinsquad.css";
import ContactUsForm from "../../contact us/ContactUsForm";
import Footer from "../../../components/Footer";
import { BsSearch } from "react-icons/bs";
import CustDialogBox from "../../../components/custdialogbox/CustDialogBox";
import ApplyNowForm from "./ApplyNowForm";
import Alertbox from "../../../components/alertbox/Alertbox";
import AlertCustDialogBox from "../../../components/alertbox/AlertCustDialogBox";

function CareerMain(props) {
  const allGreyBoxContent = [
    {
      positionName: `PHP DEVELOPER`,
      salaryName: `13K - 20K`,
      yearExperience: `6`,
      extraJobInfo: `<p>A right candidate for the right position is urgently needed. As a member of our high-aiming team, you will not have to compromise on your salary and on your career.</p>
<p>For the right candidate, there is no salary ceiling developersquad.in</p>
<p>Send your resume at : <a href="mailto:developersquad575@gmail.com">developersquad575@gmail.com</a></p>`,
    },
    {
      positionName: `FULL STACK DEVELOPER`,
      salaryName: `13K - 20K`,
      yearExperience: `6`,
      extraJobInfo: `<p>A right candidate for the right position is urgently needed. As a member of our high-aiming team, you will not have to compromise on your salary and on your career.</p>
<p>For the right candidate, there is no salary ceiling developersquad.in</p>
<p>Send your resume at : <a href="mailto:developersquad575@gmail.com">developersquad575@gmail.com</a></p>`,
    },
    {
      positionName: `REACT DEVELOPER`,
      salaryName: `13K - 20K`,
      yearExperience: `6`,
      extraJobInfo: `<p>A right candidate for the right position is urgently needed. As a member of our high-aiming team, you will not have to compromise on your salary and on your career.</p>
<p>For the right candidate, there is no salary ceiling developersquad.in</p>
<p>Send your resume at : <a href="mailto:developersquad575@gmail.com">developersquad575@gmail.com</a></p>`,
    },
    {
      positionName: `NODE JS DEVELOPER`,
      salaryName: `13K - 20K`,
      yearExperience: `6`,
      extraJobInfo: `<p>A right candidate for the right position is urgently needed. As a member of our high-aiming team, you will not have to compromise on your salary and on your career.</p>
<p>For the right candidate, there is no salary ceiling developersquad.in</p>
<p>Send your resume at : <a href="mailto:developersquad575@gmail.com">developersquad575@gmail.com</a></p>`,
    },
    {
      positionName: `REACT NATIVE DEVELOPER`,
      salaryName: `13K - 20K`,
      yearExperience: `6`,
      extraJobInfo: `<p>A right candidate for the right position is urgently needed. As a member of our high-aiming team, you will not have to compromise on your salary and on your career.</p>
<p>For the right candidate, there is no salary ceiling developersquad.in</p>
<p>Send your resume at : <a href="mailto:developersquad575@gmail.com">developersquad575@gmail.com</a></p>`,
    },
  ];
  const [useReadMore, setUseReadMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showBtn, setShowBtn] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleAlertModal, setToggleAlertModal] = useState(false);

  const handleReadMore = () => {
    setUseReadMore(!useReadMore);
  };
  function handleClose(e) {
    setToggleModal(e);
    setToggleAlertModal(true);
    setTimeout(() => {
      setToggleAlertModal(false);
    }, 3000);
  }

  // const handleReadMore = (item) => {
  //   navigate("/ReadMoreService", { state: item });
  // };

  return (
    <>
      <section className="bg_section_container1">
        <div className="bg_hero_section_container">
          <div className="nav_bar_under_text_container">
            <Navbar />

            <div className="heading_hero_section_container">
              <div className="green_container_heading_projects1">
                <div className="work_title_text_container">
                  <p className="our_work_title_text">
                    JOIN <br /> OUR SQUAD
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* menu */}
      </section>
      <div className="search_main_wrapper">
        <div className="search_input_icon_container">
          <BsSearch />
          <input
            type="search"
            id="gsearch"
            name="gsearch"
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            placeholder="Search job"
          />
        </div>
        <div className="search_result_container">
          {allGreyBoxContent
            .filter((perGreyItems) =>
              perGreyItems.positionName.toLowerCase().includes(searchTerm)
            )
            .map((perGreyItems) => {
              return (
                <div className="search_grey_container" key={Math.random()}>
                  <div className="search_flex_container">
                    <div className="search_left_container">
                      <div className="title_experience_text_wrapper">
                        <strong>{perGreyItems.positionName}</strong>
                        <p>
                          min {perGreyItems.yearExperience} months Experience
                        </p>
                      </div>
                    </div>
                    <div className="search_right_container">
                      <strong>₹ {perGreyItems.salaryName}</strong>
                    </div>
                  </div>
                  <div className="content_apply_now_btn_container">
                    <div className="left_content_apply">
                      <div className="text_read_more_flex">
                        {useReadMore ? null : (
                          <div
                            className="info_job_text"
                            dangerouslySetInnerHTML={{
                              __html:
                                perGreyItems.extraJobInfo.substring(0, 300) +
                                " ...",
                            }}
                          ></div>
                        )}
                        <span
                          className="read_more_txt_join"
                          onClick={handleReadMore}
                        >
                          {useReadMore ? "" : <>Read more &#187;</>}
                        </span>
                      </div>
                      {/* <div
              className="info_job_text"
              dangerouslySetInnerHTML={{
                __html: perGreyItems.extraJobInfo.substring(0, 300),
              }}
            ></div> */}

                      {useReadMore ? (
                        <>
                          <div
                            className="info_job_text"
                            dangerouslySetInnerHTML={{
                              __html: perGreyItems.extraJobInfo,
                            }}
                          ></div>
                          <span
                            className="read_more_txt_join"
                            onClick={handleReadMore}
                          >
                            {useReadMore ? <>Read less &#171;</> : ""}
                          </span>
                        </>
                      ) : null}
                    </div>
                    <button onClick={() => setToggleModal(true)}>
                      Apply Now
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <CustDialogBox
        dialogHeaderName="Fill up form"
        toggleDialog={toggleModal}
        open={toggleModal}
        onClose={() => {
          setToggleModal(false);
        }}
      >
        <div className="form_modal_container">
          <ApplyNowForm closeDialog={handleClose} />
        </div>
      </CustDialogBox>

      <AlertCustDialogBox
        dialogHeaderName=""
        open={toggleAlertModal}
        onClose={() => {
          setToggleAlertModal(false);
        }}
      >
        <Alertbox />
      </AlertCustDialogBox>
      <ContactUsForm />
      <Footer />
    </>
  );
}

export default CareerMain;
