import React from "react";
import "../css files/businessvdo.css";
import { useNavigate } from "react-router-dom";
const business_poster = require("../../assets/work/business_poster.jpg");
const business_vdo = require("../../assets/ads_vdo.mp4");

function BusinessVdo() {
  const navigate = useNavigate();

  return (
    <section className="main_business_vdo_container">
      <div className="under_business_vdo_container">
        <p className="work_flow_text_heading_about">BUSINESS VIDEO</p>

        <div className="green_vdo_container">
          <div className="vdo_for_business_container">
            <video
              className="video_hero_section"
              loop
              poster={business_poster}
              autoPlay
              muted
              preload="auto"
              playsInline
            >
              <source src={business_vdo} type="video/mp4" />
            </video>
          </div>
        </div>

        <div className="text_btn_container_business_vdo">
          <p className="business_vdo_paragraph_text">
            For more videos you can search out our <br /> YouTube Channel
          </p>
          <button
            className="view_more_btn_business_vdo"
            onClick={() =>
              (window.location.href =
                "https://www.youtube.com/results?search_query=developer+squad")
            }
          >
            VIEW MORE
          </button>
        </div>
      </div>
    </section>
  );
}

export default BusinessVdo;
