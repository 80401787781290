import React from "react";
import "../css files/contactusform.css";
import ContactUsFormPart from "../../components/ContactUsFormPart";
const contact_us_img = require("../../assets/contact_us_form_pic.jpg");

function ContactUsForm(props) {
  return (
    <div style={{ position: "relative" }}>
      <section className="contact_form_main_container">
        <div className="green_rect_container" />
        <div className="contact_under_form_container">
          <strong className="title_of_get_in_text">CONTACT US</strong>
          <div className="contact_form_flex_container">
            <div className="contact_left_form_container">
              <div className="form_contact_us">
                <p className="extra_form_content">
                  We help organisations to improve with our team's collective
                  knowledge and experience in Website and Application
                  Development.{" "}
                </p>
                <ContactUsFormPart />
              </div>
            </div>
            <div className="contact_rgt_form_container">
              <div className="img_form_container">
                <img src={contact_us_img} alt="contact_form_img" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContactUsForm;
