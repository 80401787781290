import React from "react";
import "../css files/careermain.css";
import Navbar from "../../components/Navbar";
import CareerDetailsPage from "./CareerDetailPage";
import SixBoxCareer from "./SixBoxCareer";
import NextRoleSection from "./NextRoleSection";
import ContactUs from "../contact us/ContactUs";
import ContactUsForm from "../contact us/ContactUsForm";
import Footer from "../../components/Footer";

function CareerMain(props) {
  return (
    <>
      <section className="bg_section_container1">
        <div className="bg_hero_section_container">
          <div className="nav_bar_under_text_container">
            <Navbar />

            <div className="heading_hero_section_container">
              <div className="green_container_heading_projects">
                <div className="work_title_text_container">
                  <p className="our_work_title_text">CAREERS</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* menu */}
      </section>
      <CareerDetailsPage />
      {/* <SixBoxCareer /> */}
      <NextRoleSection />
      <ContactUsForm />
      <Footer />
    </>
  );
}

export default CareerMain;
