import React, { useEffect, useState } from "react";
import { MdOutlineMenu } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import "../css files/developmentsec.css";
import Navbar from "../../components/Navbar";

import CustDialogBox from "../../components/custdialogbox/CustDialogBox";
import "../css files/fixbtn.css";
import { MdCall } from "react-icons/md";
import ContactUsFormPart from "../../components/ContactUsFormPart";

const developer_squad_logo = require("../../assets/ds_only_text.png");

function DevelopmentSection(props) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [openInterestedForm, setOpenInterestedForm] = useState(false);

  return (
    <section className="bg_section_container">
      <div className="bg_hero_section_container">
        <div className="nav_bar_under_text_container">
          <Navbar />

          <div
            className={
              menuOpen
                ? "development_text_container_right"
                : "fade-out-right-reverse development_text_container"
            }
          >
            <h1 className="development_text">Welcome onboard</h1>
            <p className="development_extra_content">
              Hey, You're here, so you're probably looking for ways to{" "}
              <strong>make</strong> your <strong>business</strong> more{" "}
              <strong>visible</strong> to a <strong>wider audience </strong>or
              maybe you have an <strong>innovative idea</strong> that can{" "}
              <strong>revolutionize</strong> the <strong>world</strong>. In any
              case, <strong>we're here</strong> to <strong>help you</strong>.
              Learn <strong>how</strong> and <strong>why</strong> we can{" "}
              <strong>assist</strong> you <strong>better than others</strong>.
            </p>
            <button
              id="contactUs"
              className="contact_us_btn"
              onClick={() => setToggleModal(true)}
              // onClick={() => navigate("/ContactUs")}
            >
              contact us
            </button>
          </div>
        </div>
      </div>

      {/* menu */}

      {/* <div
        id={menuOpen ? "opensidenav" : "mySidenav"}
        className="side_nav_container"
      >
        <p className="close_btn_menu" onClick={handleMenuClose}>
          &times;
        </p>
        <Link
          to="/"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          Home
        </Link>
        <Link
          to="/WorkMain"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          OUR WORK
        </Link>
        <a
          href="#services"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          OUR SERVICES
        </a>
        <a
          href="#contactus"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          CONTACT US
        </a>
        <a
          href="#workflow"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          WORK FLOW
        </a>
        <a
          href="#aboutus"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          ABOUT US
        </a>
        <a
          href="#testimonial"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          TESTIMONIAL
        </a>
        <a
          href="#projects"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          OUR PROJECTS
        </a>
      </div> */}

      <CustDialogBox
        dialogHeaderName="Contact Us"
        open={toggleModal}
        onClose={() => {
          setToggleModal(false);
        }}
      >
        <div className="form_modal_container">
          <ContactUsFormPart />
        </div>
      </CustDialogBox>
    </section>
  );
}

export default DevelopmentSection;
