import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Service from "../pages/home/Service";

function ServiceMainPage(props) {
  return (
    <>
      <section className="bg_read_more_section">
        <div className="bg_read_more_hero_section_container">
          <div className="nav_bar_under_text_container">
            <Navbar />
          </div>
          <div className="heading_hero_section_container">
            <div className="green_read_more_container_heading">
              <div className="work_title_text_container">
                <div className="our_work_read_more_title_text">SERVICES</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Service />
      <Footer />
    </>
  );
}

export default ServiceMainPage;
