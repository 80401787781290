import React, { useEffect, useState } from "react";
import "../pages/css files/footer.css";
import "../pages/css files/footerold.css";
import { contactUs } from "../service/api";
import AlertCustDialogBox from "./alertbox/AlertCustDialogBox";
import Alertbox from "./alertbox/Alertbox";
import "../pages/css files/contactusform.css";

function ContactUsFormPart(props) {
  const [alertToggleModal, setAlertToggleModal] = useState(false);
  const [disableToggle, setDisableToggle] = useState(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [desMessage, setDesMessage] = useState("");
  const [nameMessage, setNameMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    msg: "",
  });
  useEffect(() => {}, [formData]);

  async function handleSubmit(e) {
    e.preventDefault();
    if (
      emailMessage === "" &&
      nameMessage === "" &&
      desMessage === "" &&
      formData.email !== "" &&
      formData.name !== "" &&
      formData.msg !== ""
    ) {
      let response = await contactUs({
        name: formData.name,
        email: formData.email,
        msg: formData.msg,
      });
      console.log(response);

      if (response === "Your Response was Submitted") {
        setAlertToggleModal(true);
        setTimeout(() => {
          setAlertToggleModal(false);
        }, 2000);
        setFormData({ name: "", email: "", msg: "" });
      }
    }
  }
  let changeMessageStyle = (messageAfter) => {
    if (messageAfter) {
      return {
        color: "red",
        fontSize: 15,
      };
    } else {
      return {
        display: "none",
      };
    }
  };
  function handleMessage(e) {
    let msg = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      msg: msg,
    }));
    if (msg.length > 20 && msg.length < 250) {
      setDesMessage("");
    } else {
      setDesMessage(
        "Description must be greater than 20 and less than 250 characters "
      );
    }
  }

  function handleFullName(e) {
    let name = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      name: name,
    }));
    if (name.length < 100) {
      setNameMessage("");
    } else {
      setNameMessage("Full Name must be less than 100 characters ");
    }
  }

  function handleEmailInput(e) {
    console.log(e.target.value);
    let email = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      email: email,
    }));
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/; //var address = document.getElementById[email].value;
    if (reg.test(email) === false) {
      if (email === "") {
        setEmailMessage("");
      } else {
        setEmailMessage("Please enter valid email Address");
        changeMessageStyle(emailMessage);
      }
    } else {
      setEmailMessage("");
    }
  }
  return (
    <>
      <form className="all_input_container" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Enter full name"
          name="f_name"
          className="input_text_footer"
          required
          value={formData.name}
          // onChange={(e) =>
          //   setFormData((preVal) => ({
          //     ...preVal,
          //     name: e.target.value,
          //   }))
          // }
          onChange={(e) => handleFullName(e)}
        />

        <label style={changeMessageStyle(nameMessage)}>{nameMessage}</label>

        <input
          type="text"
          placeholder="Enter email"
          name="email"
          className="input_text_footer"
          required
          value={formData.email}
          // onChange={(e) =>
          //   setFormData((preVal) => ({
          //     ...preVal,
          //     email: e.target.value,
          //   }))
          // }
          onChange={(email) => handleEmailInput(email)}
        />

        <label style={changeMessageStyle(emailMessage)}>{emailMessage}</label>

        <textarea
          id="msg"
          name="msg"
          rows="4"
          cols="50"
          className="text_area_container"
          placeholder="Type your message..."
          required
          value={formData.msg}
          // onChange={(e) =>
          //   setFormData((preVal) => ({
          //     ...preVal,
          //     msg: e.target.value,
          //   }))
          // }
          onChange={(msg) => handleMessage(msg)}
        />

        <label style={changeMessageStyle(desMessage)}>{desMessage}</label>

        <button className="submit_btn">SUBMIT</button>
      </form>
      <AlertCustDialogBox
        dialogHeaderName=""
        open={alertToggleModal}
        onClose={() => {
          setAlertToggleModal(false);
        }}
      >
        <Alertbox />
      </AlertCustDialogBox>
    </>
  );
}

export default ContactUsFormPart;
