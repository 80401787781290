import React from "react";

function InputTextBox(props) {
  return (
    <div className="input_label_field_container">
      <label>{props.labelName}</label>
      <input
        type={props.inputType}
        placeholder={props.placeholderText}
        id={props.inputId}
        name={props.nameText}
        defaultValue={props.defaultValue}
        value={props.value}
        disabled={props.disabled ? props.disabled : false}
        autoComplete="off"
        patterns={props.inputPattern}
        onChange={props.onChange}
        max={props.max}
        required
      />
    </div>
  );
}

export default InputTextBox;
