import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../src/pages/home/Home";
import WorkMain from "../src/pages/work/WorkMain";
import AboutusMain from "./pages/about us/AboutusMain";
import ProjectMain from "./pages/projects/ProjectMain";
import ScrollToTop from "./components/ScrollToTop";
import ContactUs from "./pages/contact us/ContactUs";
import ReadMoreService from "./pages/home/ReadMoreService/ReadMoreService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import FixButton from "./components/FixButton";
import CareerMain from "./pages/Career/CareerMain";
import JoinSquadMain from "./pages/Career/joinsquad/JoinSquadMain";
import { MdCall } from "react-icons/md";
import CustDialogBox from "./components/custdialogbox/CustDialogBox";
import Service from "./pages/home/Service";
import ContactUsFormPart from "./components/ContactUsFormPart";
import ServiceMainPage from "./service/ServiceMainPage";
import ScrollTopBtn from "./components/ScrollTopBtn";
import { ThemeProvider } from "styled-components";

function App() {
  const [showBtn, setShowBtn] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const theme = {
    colors: {
      heading: "rgb(24 24 29)",
      text: "rgb(24 24 29)",
      white: "#fff",
      black: " #212529",
      helper: "#8490ff",
      bg: "rgb(249 249 255)",
      footer_bg: "#0a1435",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    media: { mobile: "768px", tab: "998px" },
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<Home />} />
          {/* <Route path="/#services" exact element={<Home />} /> */}
          <Route path="/WorkMain" exact element={<WorkMain />} />
          <Route path="/AboutusMain" exact element={<AboutusMain />} />
          <Route path="/ProjectMain" exact element={<ProjectMain />} />
          <Route path="/ContactUs" exact element={<ContactUs />} />
          <Route path="/ReadMoreService" exact element={<ReadMoreService />} />
          <Route path="/PrivacyPolicy" exact element={<PrivacyPolicy />} />
          <Route path="/Terms" exact element={<Terms />} />
          <Route path="/CareerMain" exact element={<CareerMain />} />
          <Route path="/JoinSquadMain" exact element={<JoinSquadMain />} />
          <Route path="/Service" exact element={<Service />} />
          <Route path="/ServiceMainPage" exact element={<ServiceMainPage />} />
        </Routes>
        <FixButton />
        <ScrollTopBtn />
        <button
          className="fix_btn_container"
          onMouseEnter={() => setShowBtn(true)}
          onMouseLeave={() => setShowBtn(false)}
          onClick={() => setToggleModal(true)}
        >
          {showBtn ? (
            <p className="fix_text">
              Contact Us <MdCall />
            </p>
          ) : (
            <p className="fix_text">
              <MdCall />
            </p>
          )}
        </button>
        <CustDialogBox
          dialogHeaderName="Contact Us"
          open={toggleModal}
          onClose={() => {
            setToggleModal(false);
          }}
        >
          <div className="form_modal_container">
            <ContactUsFormPart />
          </div>
        </CustDialogBox>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
