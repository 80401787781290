import React from "react";
import "../css files/getintouch.css";
import { MdOutlineEmail, MdPhone } from "react-icons/md";
const get_in_touch_img = require("../../assets/get_In_touch_img.png");

function GetInTouch(props) {
  return (
    <section className="get_in_touch_main_container">
      <div className="get_in_touch_under_main_container">
        <strong className="title_of_get_in_text">
          GET IN TOUCH WITH US NOW
        </strong>

        <div className="get_in_touch_container">
          <div className="get_in_touch_left_container">
            <div className="get_in_touch_img_container">
              <img
                src={get_in_touch_img}
                alt="get_in_touch_developer_squad"
                loading="lazy"
              />
            </div>
          </div>
          <div className="get_in_touch_right_container">
            <p className="get_in_touch_content">
              We help organisations to improve with our team's collective
              knowledge and experience in Website and Application Development.{" "}
            </p>
            <strong className="get_in_touch_right_title">
              Contact Information
            </strong>
            <p className="get_in_touch_content">
              Feel free to contact one of our online representatives at any time
              using our Live Chat system or contact us via Email & Phone.
            </p>

            <div className="img_social_container">
              <a href="mailto: developersquad575@gmail.com">
                <MdOutlineEmail className="mail_icon" />{" "}
                developersquad575@gmail.com
              </a>
              <a href="tel:+919726609747">
                <MdPhone className="mail_icon" /> +91 9726609747
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GetInTouch;
