import React from "react";
import { useNavigate, Link } from "react-router-dom";
import "../css files/projectsection.css";

const proj_img_1 = require("../../assets/image1.jpg");
const proj_img_2 = require("../../assets/image2.jpg");
const proj_img_3 = require("../../assets/image3.jpg");
const proj_img_4 = require("../../assets/image4.jpg");

const ProjectSection = () => {
  const navigate = useNavigate();
  return (
    <section className="proj_main_container" id="projects">
      <div className="section_under_main_container">
        <div className="green_container_project_section">
          <div className="green_two_section_container">
            <div className="first_green_section_container">
              <div className="four_all_green_container">
                <a
                  href="https://dynamicwec.net/"
                  className="img_for_proj_container"
                >
                  <img src={proj_img_1} alt="ds_img" />
                </a>
                <a
                  href="https://igniter.developersquad.in/"
                  className="img_for_proj_container"
                >
                  <img src={proj_img_2} alt="ds_img" />
                </a>
                <a
                  href="https://www.dietdelightbh.com/"
                  className="img_for_proj_container"
                >
                  <img src={proj_img_3} alt="ds_img" />
                </a>
                <a
                  href="https://igniterlp.developersquad.in/"
                  className="img_for_proj_container"
                >
                  <img src={proj_img_4} alt="ds_img" />
                </a>
              </div>
            </div>
            <div className="sec_green_section_container">
              <p className="title_project_text">OUR PROJECT</p>
              <p className="extra_content_of_project">
                We are the developers squad, a group of professionals who help
                ease, renovate, re-establish, and gear up the client's work.{" "}
              </p>

              <p className="extra_content1_of_project">
                {" "}
                Basically, we help bring the person's ideas to life. We've been
                doing this
              </p>

              <button
                className="view_more_btn_by_project"
                onClick={() => navigate("/ProjectMain")}
              >
                VIEW MORE
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection;
