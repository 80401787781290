import React, { useEffect, useState } from "react";
import { MdOutlineMenu } from "react-icons/md";
import { Link } from "react-router-dom";
import "../css files/developmentsec.css";
import "../css files/workherosection.css";
import "../css files/aboutusherosection.css";
import Navbar from "../../components/Navbar";

const developer_squad_logo = require("../../assets/ds_only_text.png");

function AboutUsHeroSection(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuBtn = () => {
    console.log("menu");
    setMenuOpen(true);

    document.body.style.backgroundColor = "rgba(0,0,0,1)";
  };

  const handleMenuClose = () => {
    console.log("close");
    setMenuOpen(false);
  };
  return (
    <section className="bg_section_container1">
      <div className="bg_hero_section_container">
        <div className="nav_bar_under_text_container">
          <Navbar />

          <div className="heading_hero_section_container">
            <div className="green_container_heading_about_us">
              <div className="work_title_text_container">
                <p className="our_work_title_text">ABOUT US</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* menu */}

      <div
        id={menuOpen ? "opensidenav" : "mySidenav"}
        className="side_nav_container"
      >
        <p className="close_btn_menu" onClick={handleMenuClose}>
          &times;
        </p>
        <Link
          to="/"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          Home
        </Link>
        <Link
          to="/"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          OUR SERVICES
        </Link>
        <Link
          to="/"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          CONTACT US
        </Link>
        <Link
          to="/"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          WORK FLOW
        </Link>
        <Link
          to="/"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          ABOUT US
        </Link>
        <Link
          to="/"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          TESTIMONIAL
        </Link>
        <Link
          to="/"
          className={menuOpen ? "link_menu_text" : "link_hidden_text"}
        >
          OUR PROJECTS
        </Link>
      </div>
    </section>
  );
}

export default AboutUsHeroSection;
