import React from "react";
import "../css files/imgslider.css";
import Carousel from "react-elastic-carousel";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
const imgSlider1 = require("../../assets/work/pic1.png");
const imgSlider2 = require("../../assets/work/pic2.png");
const imgSlider3 = require("../../assets/work/pic3.png");
const imgSlider4 = require("../../assets/work/pic4.png");
const imgSlider5 = require("../../assets/work/pic5.png");

const allImgSliderItem = [
  {
    imgSlider: imgSlider1,
  },
  {
    imgSlider: imgSlider2,
  },
  {
    imgSlider: imgSlider3,
  },
  {
    imgSlider: imgSlider4,
  },
  {
    imgSlider: imgSlider5,
  },
];

const ImgSlider = () => {
  const customSlider = React.useRef();
  return (
    <div className="main_video_slider_container">
      <div className="under_main_img_slider_container">
        <div className="main_title_content_container">
          <p className="work_flow_text_heading_about">
            DIFFERENT UI{" "}
            <span className="highlighted_title_text_about">DESIGNS</span> OF{" "}
            <br />
            <span className="highlighted_title_text_about">WEBSITE</span> &{" "}
            <span className="highlighted_title_text_about">APPLICATION</span>
          </p>
        </div>

        <div className="proj_img_slider_container">
          <Carousel
            ref={customSlider}
            itemsToShow={1}
            showArrows={false}
            easing="cubic-bezier(1,.15,.55,1.54)"
            tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
            transitionMs={700}
          >
            {allImgSliderItem.map((perImgSlider) => {
              return (
                <div className="proj_img_container" key={Math.random()}>
                  <img
                    src={perImgSlider.imgSlider}
                    alt="proj_img_one"
                    className="proj_img_one"
                  />
                </div>
              );
            })}
          </Carousel>
          <div className="lang_btn_container">
            <button
              className="left_icon_lang_btn same_btn_styling"
              onClick={() => customSlider.current.slidePrev()}
            >
              <MdKeyboardArrowLeft />
            </button>
            <button
              className="right_icon_lang_btn same_btn_styling"
              onClick={() => customSlider.current.slideNext()}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgSlider;
