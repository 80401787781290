import React, { useEffect, useState, useRef } from "react";
import { MdMenu, MdClose } from "react-icons/md";
import { NavLink, Link, useNavigate } from "react-router-dom";
import "../pages/css files/navbar.css";
const Ds_logo = require("../assets/ds_only_text.png");
const call = require("../assets/phone.png");

function Navbar(props) {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(true);

  function handleHide() {
    setShowMenu(false);
  }
  return (
    <div>
      {/* <div className="navbar_logo">
    <img src={Ds_logo} alt="developer_squad_logo" loading="lazy" />
  </div> */}

      {/* <button htmlFor="check_menu" className="menu_btn_nav">
  <MdMenu className="menu_btn_icon" />
</button> */}
      <nav>
        <div className="navbar">
          <div className="container nav-container">
            <Link className="navbar_logo" to="/">
              <img src={Ds_logo} alt="developer_squad_logo" loading="lazy" />
            </Link>
            <a href="tel:+919726609747" className="call_img_container">
              <img src={call} alt="call" />
            </a>
            <input className="checkbox" type="checkbox" name="" id="" />
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
            {showMenu ? (
              <div className="menu-items">
                {/* <div
                  className="close_btn_under_menu_items"
                  onClick={handleHide}
                >
                  <MdClose className="close_white_color" />
                </div> */}
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/ServiceMainPage">Services</NavLink>
                </li>
                <li>
                  <NavLink to="/WorkMain">our work</NavLink>
                </li>
                <li>
                  <NavLink to="/AboutusMain">About us</NavLink>
                </li>
                <li>
                  <NavLink to="/ContactUs">contact us</NavLink>
                </li>
                <a className="call_us_text" href="tel:+919726609747">
                  Call Us : +91 9726609747
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
