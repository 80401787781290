import React from "react";
import "../css files/ourteam.css";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import Carousel from "react-elastic-carousel";
const harshiv = require("../../assets/aboutus/harshiv .png");
const ps_photo = require("../../assets/aboutus/veeru.png");
const meet = require("../../assets/aboutus/meet.png");
const vinit = require("../../assets/aboutus/vinit.jpeg")

const allRenderTeam = [
  {
    personPhoto: meet,
    personName: "MEET PATEL",
    personInfo: "Frontend developer (Website & application)",
  },
  {
    personPhoto: harshiv,
    personName: "HARSHIV PATEL",
    personInfo: "Full Stack Developer (Website & application)",
  },
  {
    personPhoto: ps_photo,
    personName: "VIRENDRASINH PARMAR",
    personInfo: "Frontend developer & UI designer ",
  },
  {
    personPhoto: vinit,
    personName: "VINIT KATHIRIA",
    personInfo: "Project manager",
  },
  // {
  //   personPhoto: person_photo,
  //   personName: "Coming soon",
  //   personInfo: "CEO",
  // },
];

const OurTeam = () => {
  const customSlider = React.useRef();
  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3, pagination: false },
    { width: 1000, itemsToShow: 3, pagination: false },
  ];

  const renderTeam = allRenderTeam.map((perRenderTeam) => {
    return (
      <div className="photo_team_container" key={Math.random()}>
        <img
          src={perRenderTeam.personPhoto}
          alt="person_photo"
          className="person_photo_self"
        />
        <div className="name_and_subname_container">
          <p className="name_photo_text">{perRenderTeam.personName}</p>
          <p className="sub_text_photo_text" style={{ textTransform: 'capitalize' }}>{perRenderTeam.personInfo}</p>
        </div>
      </div>
    );
  });
  return (
    <section className="our_team_main_container">
      <div className="our_team_under_container">
        <div className="meet_the_team_bg_container">
          <div className="team_all_content_containert">
            <p className="sub_text_title_text">THE BACKBENCHERS</p>
            <p className="our_team_title_text">MEET THE TEAM</p>
            <p className="extra_team_word_text">
              If you <strong>contact us</strong>, we will <strong>put</strong>{" "}
              you in <strong>touch</strong> with a <strong>member</strong> of
              our <strong>professional team</strong> listed below.
            </p>
          </div>
        </div>
        <div className="team_photo_slider_container">
          <Carousel
            breakPoints={breakPoints}
            ref={customSlider}
            enableAutoPlay={false}
            showArrows={false}
            autoPlaySpeed={1500}
          >
            {renderTeam}
          </Carousel>

          <div className="btn_group_container">
            <div className="lang_btn_container">
              <button
                className="left_icon_lang_btn same_btn_styling"
                onClick={() => customSlider.current.slidePrev()}
              >
                <MdKeyboardArrowLeft />
              </button>
              <button
                className="right_icon_lang_btn same_btn_styling"
                onClick={() => customSlider.current.slideNext()}
              >
                <MdKeyboardArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
