import React from "react";
import { useNavigate } from "react-router-dom";
import "../css files/nextrolesection.css";
const business_img = require("../../assets/career/businees_mg.jpg");

function NextRoleSection(props) {
  const navigate = useNavigate();
  return (
    <div className="next_under_section_container">
      <div className="flex_container1">
        <div className="next_img_container">
          <img src={business_img} alt="business" />
        </div>
        <div className="next_rect_container">
          <p>
            READY TO FIND YOUR <br />
            NEXT ROLE ?
          </p>
          <button
            className="position_see_btn"
            onClick={() => navigate("/JoinSquadMain")}
          >
            See position
          </button>
        </div>
        <div className="vertical_rect" />
      </div>
    </div>
  );
}

export default NextRoleSection;
