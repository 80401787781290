import React, { useEffect, useState } from "react";
import { MdOutlineMenu } from "react-icons/md";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import "../css files/developmentsec.css";
import "../css files/workherosection.css";

function DevelopmentSection(props) {
  return (
    <section className="bg_section_container1">
      <div className="bg_hero_section_container">
        <div className="nav_bar_under_text_container">
          <Navbar />

          <div className="heading_hero_section_container">
            <div className="green_container_heading">
              <div className="work_title_text_container">
                <p className="our_work_title_text">OUR WORK</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* menu */}
    </section>
  );
}

export default DevelopmentSection;
